export const ICON_NAME = {
  FACEBOOK: "FACEBOOK",
  YOUTUBE: "YOUTUBE",
  INSTAGRAM: "INSTAGRAM",
  MESSENGER: "MESSENGER",
  PHONE_ANDROID: "PHONE_ANDROID",
  PASSWORD: "PASSWORD",
  ARROW_DROP_DOWN: "ARROW_DROP_DOWN",
  MORE_HORIZ: "MORE_HORIZ",
  LIKE_FILL: "LIKE_FILL",
  DISLIKE_FILL: "DISLIKE_FILL",

  ROUNDED: {
    SEND: "SEND_ROUNDED",
    FACEBOOK: "FACEBOOK_ROUNDED",
    ARROW_LEFT: "ARROW_LEFT_ROUNDED",
    ARROW_RIGHT: "ARROW_RIGHT_ROUNDED",
    STAR: "STAR_ROUNDED"
  },
  OUTLINED: {
    MAIL: "MAIL_OUTLINED",
    LOCATION: "LOCATION_OUTLINED",
    ACCESS_TIME: "ACCESS_TIME_OUTLINED",
    LOCAL_MALL: "LOCAL_MALL_OUTLINED",
    VERIFIED: 'VERIFIED_OUTLINED',
    FACT_CHECK: 'FACT_CHECK_OUTLINED',
    CHAT: 'CHAT_OUTLINED',
    LOCAL_SHIPPING: 'LOCAL_SHIPPING_OUTLINED',
  },
  FEATHER: {
    CHEVRON_LEFT: "CHEVRON_LEFT",
    CHEVRON_RIGHT: "CHEVRON_RIGHT",
    CHEVRON_DOWN: "CHEVRON_DOWN",
    HELP_CIRCLE: "HELP_CIRCLE",
    SMARTPHONE: "SMARTPHONE",
    USER: "USER",
    SEARCH: "SEARCH",
    HEART: "HEART",
    SHOPPING_BAG: "SHOPPING_BAG",
    LIKE: "LIKE",
    DISLIKE: "DISLIKE",
    STAR: "STAR",
    STAR_FILLED: "STAR_FILLED",
    PHONE: "PHONE",
    EDIT: "EDIT",
    MENU: "MENU",
    X: "X",
    THUMBS_UP: 'THUMBS_UP',
    THUMBS_DOWN: 'THUMBS_DOWN',
    PHONE_PRODUCT_DETAIL: 'PHONE_PRODUCT_DETAIL',
    BELL: 'BELL',
    INFO: 'INFO',
    LIST: 'LIST',
    EDIT_3: 'EDIT_3',
    LOG_OUT: 'LOG_OUT',
  },
};
